<template>
  <div class="terms" v-if="page">
    <div class="outer-container">
      <div class="inner-container">
        <div class="terms-content">
          <h2>Privacy policy</h2>
          <div class="text-18">September, 2018</div>
          <h3>Quick navigation</h3>
            <ol>
              <li><a href="#Introduction">Introduction</a></li>
              <li><a href="#The kinds of personal information we collect">The kinds of personal information we
                collect</a></li>
              <li><a href="#How we collect personal information">How we collect personal information</a></li>
              <li><a href="#How we hold personal information">How we hold personal information</a></li>
              <li><a href="#How we use personal information collected from you">How we use personal information
                collected by you</a></li>
              <li><a href="#Who we may disclose your information to">Who we may disclose your information to</a></li>
              <li><a href="#Access, correction and erasure of your personal information">Access, correction and erasure
                of your personal information</a></li>
              <li><a href="#Anonymity/pseudonymity">Anonymity/pseudonymity</a></li>
              <li><a href="#Information that may be collected automatically - IP addresses and Cookies">Information that
                may be collected automatically - IP addresses and Cookies</a></li>
              <li><a href="#Security">Security</a></li>
              <li><a href="#Your privacy choices and how to make a complaint">Your privacy choices and how to make a
                complaint</a></li>
              <li><a href="#Changes to this Privacy Policy">Changes to this Privacy Policy</a></li>
              <li><a href="#CONTACT INFORMATION AND POLICY UPDATES">Contact information and Policy updates</a></li>
            </ol>
            <h3><a id="Introduction" name="Introduction">1. Introduction</a></h3>
            <p>Wine Australia (‘we’ or ‘us’ or ‘our’), a statutory body established under the<em> Wine Australia Act
              2013</em> (Cth), (ABN 89 636 749 924) is bound by the <em>Privacy Act 1988</em> (Privacy Act) which
              dictates how we collect, store and use your personal information. &nbsp;&nbsp;Because we conduct some
              activities globally, we are also bound by various international laws relating to privacy and data
              protection. For example, we are required to comply with the EU General Data Protection Regulation when
              dealing with personal information of EU citizens. Accordingly, Wine Australia has adopted a comprehensive
              privacy policy capturing the requirements in all markets in which it operates.</p>
            <p>‘Personal information’ is information about an identifiable individual, as defined by applicable law. We
              are committed to protecting the privacy of any personal information we hold about individuals in
              accordance with the applicable laws. As such, we only collect personal information where it is reasonably
              necessary for us to do so in performing our statutory functions and related activities.</p>
            <p>This policy explains how we handle the personal information we collect and receive from you through use
              of our websites and mobile applications (‘Sites’), and other digital or offline services controlled by
              Wine Australia that refer to this Privacy Policy (collectively, ‘Our Services’). In addition, this policy
              sets out the rights and obligations of both you and Wine Australia in relation to the handling of your
              personal information, and how you can request access to, correction or deletion of your personal
              information we hold.</p>
            <h3><a id="The kinds of personal information we collect"
                   name="The kinds of personal information we collect">2. The kinds of personal information we
              collect</a></h3>
            <p>In conducting our statutory role and associated activities, we may collect and hold and hold your
              personal information if:</p>
            <ul>
              <li><span class="list-bullet"></span>you sign-up or participate in an event being run by us</li>
              <li><span class="list-bullet"></span>you subscribe to communication disseminated by us</li>
              <li><span class="list-bullet"></span>you contribute to or participate in a survey run by us</li>
              <li><span class="list-bullet"></span>you use Our Services</li>
              <li><span class="list-bullet"></span>you are a licensed exporter of wine from Australia</li>
              <li><span class="list-bullet"></span>you are pay a charge or levy through which we are funded</li>
              <li><span class="list-bullet"></span>we procure goods and/or services from you or through your employer or
                principal, or
              </li>
              <li><span class="list-bullet"></span>you are employed by us.</li>
            </ul>
            <p>The types of personal information we collect and hold will depend on the nature of your dealings with us
              and may (but does not always) include:</p>
            <ul>
              <li><span class="list-bullet"></span>your name, gender and date of birth</li>
              <li><span class="list-bullet"></span>any information about you which you provide through promotions,
                competitions or customer surveys
              </li>
              <li><span class="list-bullet"></span>information collected as part of a levy return process</li>
              <li><span class="list-bullet"></span>your personal and/or work contact information – including postal and
                residential address, telephone numbers and email addresses
              </li>
              <li><span class="list-bullet"></span>information provided by you to us via our licensing and approval
                system (WALAS)
              </li>
              <li><span class="list-bullet"></span>information about your commercial or business operations within the
                wine sector
              </li>
              <li><span class="list-bullet"></span>information about any regional association that you are a member of
              </li>
              <li><span class="list-bullet"></span>your credit card or account details (during the course of conducting
                financial transactions with you)
              </li>
              <li><span class="list-bullet"></span>Australian Business Numbers (which are only used in accordance with
                the Privacy Act), and
              </li>
              <li><span class="list-bullet"></span>your opinion on issues relating to the wine sector.</li>
            </ul>
            <p>If necessary, we may also ask you to provide it with additional personal information from time to time,
              including when you call us.</p>
            <p>We will always ask you for permission to collect your personal information prior to collecting it.</p>
            <h3><a id="How we collect personal information" name="How we collect personal information">3. How we collect
              personal information</a></h3>
            <p>We may collect personal information from you with consent in the following ways:</p>
            <ul>
              <li><span class="list-bullet"></span>when you access or request information from our Sites</li>
              <li><span class="list-bullet"></span>through the Wine Australia Licensing and Approval system (WALAS)</li>
              <li><span class="list-bullet"></span>when you participate in a competition or trade promotion conducted by
                us or one of our related companies
              </li>
              <li><span class="list-bullet"></span>when you participate in a survey</li>
              <li><span class="list-bullet"></span>from you in person at trade shows, road shows, seminars and events
              </li>
              <li><span class="list-bullet"></span>through social media sites, apps and e-commerce platforms</li>
              <li><span class="list-bullet"></span>in other correspondence with you or your agents, employees or
                subcontractors
              </li>
              <li><span class="list-bullet"></span>when you lodge a return through our system in accordance with the
                requirements of the <em>Primary Industries Levies and Charges Collection Act 1991</em> (<strong><em>Collection
                  Act</em></strong>) and the <em>Primary Industries Levies and Charges Collection Regulations 1991</em>
                (<strong><em>Collection Regulations</em></strong>), and
              </li>
              <li><span class="list-bullet"></span>in performing our statutory functions.</li>
            </ul>
            <h3><a id="How we hold personal information" name="How we hold personal information">4. How we hold personal
              information</a></h3>
            <p>We hold personal information in physical records, on our information management system or on mailing
              lists.</p>
            <p>We take reasonable steps to hold all hard copy and electronic records of your personal information in a
              secure manner to ensure that it is protected from misuse, interference and loss, and unauthorised access,
              modification or disclosure.</p>
            <h3><a id="How we use personal information collected from you"
                   name="How we use personal information collected from you">5. How we use personal information
              collected from you</a></h3>
            <p>We collect, use and exchange your information for the purpose of:</p>
            <ul>
              <li><span class="list-bullet"></span>performing our statutory functions</li>
              <li><span class="list-bullet"></span>managing our relationship with you</li>
              <li><span class="list-bullet"></span>responding to your enquiries</li>
              <li><span class="list-bullet"></span>facilitating the collection of the Wine Export Charge, giving
                statements of export required to be provided under the regulation 5 of Schedule 35 of the Collection
                Regulations and assisting you in submitting returns as required under the Collection Regulations
              </li>
              <li><span class="list-bullet"></span>where you have given us your consent, providing you with (or
                permitting selected third parties to provide to you with), information on services, events and
                activities run by Wine Australia
              </li>
              <li><span class="list-bullet"></span>where you have given us your consent, updating you on issues relating
                to the wine sector (for example, through distribution of various newsletters)
              </li>
              <li><span class="list-bullet"></span>conducting specific projects in which you have chosen to participate
              </li>
              <li><span class="list-bullet"></span>administering events and competitions</li>
              <li><span class="list-bullet"></span>analysing and improving the services (and content) we offer and the
                events and activities we undertake
              </li>
              <li><span class="list-bullet"></span>personalising the way that our online content is presented to you and
                to allow you to participate in interactive features when you choose to do so
              </li>
              <li><span class="list-bullet"></span>to carry out our obligations arising from contracts entered into
                between you and us, and
              </li>
              <li><span class="list-bullet"></span>any other purposes notified to you at the time your personal
                information is collected.
              </li>
            </ul>
            <h4>Marketing</h4>
            <p>You may also choose to receive marketing information from us, such as offers regarding Our Services,
              invitations, or other notifications and offers. In circumstances where we have obtained your consent, we
              will use your contact and other personal information to contact you at any time in the future to:</p>
            <ol>
              <li>provide you with information and updated information about Our Services;</li>
              <li>obtain sponsorships, provide training and other educational activities, seek consultancy services or
                to contact you for surveys; and/or
              </li>
              <li>communicate with you in relation to any other matters in respect of which you have consented to Wine
                Australia contacting you.
              </li>
            </ol>
            <p>At any time, you may opt out of the use of your personal information for marketing purposes by contacting
              us at the contact details set out below or by using the unsubscribe mechanism in the relevant marketing
              material you receive from us.</p>
            <h3><a id="Who we may disclose your information to" name="Who we may disclose your information to">6.&nbsp;Who
              we may disclose your information to</a></h3>
            <p>Your personal information may be disclosed:</p>
            <ul>
              <li><span class="list-bullet"></span>where the use or disclosure is required at law</li>
              <li><span class="list-bullet"></span>to Wine Australia’s related entities, International offices and
                representatives (for the purpose of Wine Australia’s function and activity only)
              </li>
              <li><span class="list-bullet"></span>to the Australian Government Department of Agriculture, Water and the
                Environment&nbsp;for the purpose of facilitating the administration and collection of the Wine Export
                Charge
              </li>
              <li><span class="list-bullet"></span>specialist advisors to Wine Australia who have been engaged to
                provide Wine Australia with legal, administrative, financial, insurance, research, marketing or other
                services
              </li>
              <li><span class="list-bullet"></span>where you have given us consent</li>
              <li><span class="list-bullet"></span>to avoid, lessen or prevent a serious emergency or crime. (If we use
                or disclose personal information about you in those circumstances we will make a written record of such
                use or disclosure)
              </li>
              <li><span class="list-bullet"></span>to contractors who provide services to us, including database
                contractors located overseas
              </li>
              <li><span class="list-bullet"></span>to third party companies which Wine Australia may have commercial or
                non-commercial relationships with for the purpose of promoting wine events and products, and
              </li>
              <li><span class="list-bullet"></span>any other person authorised implicitly or expressly, when the
                personal information is provided or collected by us.
              </li>
            </ul>
            <h3><a id="Access, correction and erasure of your personal information"
                   name="Access, correction and erasure of your personal information">7. Access, correction and erasure
              of your personal information</a></h3>
            <p>You may request access to, correction or erasure of your personal information at any time by emailing us
              at <a href="mailto:enquiries@wineaustralia.com">enquiries@wineaustralia.com</a>. You may also object to
              data processing for the purposes of profiling used for business interests. There is no requirement for you
              to provide a reason for such request. We may deny access to personal information where we are required by
              law to withhold the information, or where the requested access would compromise the privacy of others.</p>
            <h3><a id="Anonymity/pseudonymity" name="Anonymity/pseudonymity">8. Anonymity/pseudonymity</a></h3>
            <p>You have a general right to anonymity/pseudonymity when dealing with us. However, if you do not provide
              us with the personal information that we request, our ability to provide services to you may be
              diminished.</p>
            <h3><a id="Information that may be collected automatically - IP addresses and Cookies"
                   name="Information that may be collected automatically - IP addresses and Cookies">9. Information that
              may be collected automatically - IP addresses and Cookies</a></h3>
            <p>We use cookies and collect IP addresses. An IP address is a number that can uniquely identify a specific
              computer or other network device on the internet from visitors to our Sites. Consequently, we may collect
              technical data from you as well as other related information about your device, systems, application
              software, and peripherals. Some of this information may be capable of personally identifying you.</p> <h4>
              Cookie options</h4>
            <p>When using our corporate website (wineaustralia.com), you will have the following options below in
              relation to tracking technologies such as cookies (‘<strong>Cookie Options</strong>’):</p>
            <ol>
              <li><strong>Accept All Cookies</strong>: This is our default system level and allows the Site’s optimum
                functionality. It allows all cookies, including third-party cookies, to track your device. You may alter
                your consent to any of the other two Cookie Options at any time.
              </li>
              <li><strong>Essential cookies</strong> are cookies which are required for all necessary Site
                functionality. This includes authentication, language selection etc. Your device will not be tracked.
                You may alter your consent to any of the other two Cookie Options at any time.
              </li>
              <li><strong>Deny all cookies and tracking</strong> means you do not consent to any cookie tracking. Please
                note that some cookies are essential to the functioning of our Sites and deleting or disabling them will
                reduce Site functionality.
              </li>
            </ol>
            <h5 style="margin-left: 36pt;">Digital Asset Library</h5>
            <p style="margin-left: 36pt;">When using our digital asset library (wineaustralia.imagegallery.me), which is
              hosted on a platform owned by a third party provider, your user's IP address, navigational and ordering
              information may be collected. The platform uses cookies for functionality such as basic search, browsing,
              placing orders, retrieving previous transactions and save assets to work correctly. Cookies on this
              platform are necessary for providing each registered user access to their own personal information.</p>
            <p style="margin-left: 36pt;">A user may delete or disable the use of cookies on our digital asset library,
              at any time, via&nbsp;<a href="https://www.google.com.au/search?q=disable+cookies" target="_blank">browser
                settings</a>.</p>
            <p style="margin-left: 36pt;">When a user first registers to use our digital asset library, the user is
              asked for permission to use cookies. At that point, the user can decline the request and cancel the
              registration submission. A registered user may also deregister their account at any time.</p>
            <p style="margin-left: 36pt;"><a href="https://support.lookatmedam.com/portal/kb/articles/cookies"
                                             target="_blank">Click here</a>&nbsp;for more information about cookies used
              on our digital asset library.</p>
            <p>In some cases, third parties such as Google Analytics may use cookies on our communication channels in
              connection with online services like Site analytics, surveys and advertising. This may allow them to
              collect information about your use of our resources, which they may store in countries other than
              Australia. If you do not want your information used by Google Analytics, you can install the Google
              Analytics opt-out browser add-on at the <a href="https://tools.google.com/dlpage/gaoptout">Google
                Analytics opt-out page</a><a href="https://tools.google.com/dlpage/gaoptout" target="_blank">.</a></p>
            <h3><a id="Security" name="Security">10. Security</a></h3>
            <p>Wine Australia has taken reasonable steps regarding physical and technical access intrusion security
              measures to protect the personal information we hold against loss, misuse, interference and from
              unauthorised access, alteration or disclosure. However, if you are considering sending us any personal
              information through any of our communication channels, please be aware that the information may be
              insecure in transit, particularly where no encryption is used (e.g. email, standard HTTP). You must also
              take care to protect your usernames and passwords and notify us as soon as possible if you become aware of
              any security breaches.</p>
            <p>Where there is a data breach that is likely to cause serious harm Wine Australia is required to notify
              individuals and the relevant authorities, unless remedial action has been taken. We will manage these in
              accordance with the <em>Privacy Act 1988</em>, and any other relevant laws and regulations in force at the
              time, together with with this Privacy Policy and our internal policies and procedures related to the
              protection of personal information and security incident response plans.</p>
            <h3><a id="Your privacy choices and how to make a complaint"
                   name="Your privacy choices and how to make a complaint">11. Your privacy choices and how to make a
              complaint</a></h3>
            <p>You may contact us at any time if you have questions about this Privacy Policy or the personal
              information we collect about you. Under applicable privacy laws, you may be entitled to request access to
              and/or rectify, remove your personal information, or object to any use of your personal information.</p>
            <p>If you wish to make a complaint about a breach of this Privacy Policy or the Australian Privacy
              Principles of the Privacy Act 1988 (Cth) you can contact us at <a
                  href="mailto:enquiries@wineaustralia.com">enquiries@wineaustralia.com</a>. You will need to provide us
              with all relevant details of your complaint and any supporting evidence.<br> <br> We will refer your
              complaint to our Privacy Officer who will investigate your complaint and determine the steps that we will
              undertake to resolve it. We will contact you if we require any additional information from you and will
              notify you, in writing, of the outcome of the investigation. If you are not satisfied with our
              determination, you can contact us to discuss your concerns or contact to the Australian Privacy
              Commissioner via the contact details set out below.</p>
            <h3><a id="Changes to this Privacy Policy" name="Changes to this Privacy Policy">12. Changes to this Privacy
              Policy</a></h3>
            <p>This Privacy Policy may be updated from time to time so you should check it each time Our Services refer
              to this Privacy Policy. The date of the most recent revisions will appear at the bottom of this Privacy
              Policy. If you do not agree with theapplicable version at the time of using Our Services, please do not
              continue to use our Services. If you do continue to use our communication platforms, you accept the terms
              of our updated Privacy Policy. If material changes are made to our Privacy Policy we will notify you by
              placing a notice on Our Sites.</p>
            <h3><a id="CONTACT INFORMATION AND POLICY UPDATES" name="CONTACT INFORMATION AND POLICY UPDATES">13. CONTACT
              INFORMATION AND POLICY UPDATES</a></h3>
            <p>If you have questions or complaints about our privacy practices or procedures or if you would like to
              request to access, correct or amend any personal information that Wine Australia holds about you, please
              contact our Privacy Officer in writing at:</p>
            <table border="0" cellpadding="0" cellspacing="0" style="width: 70%;" width="70%">
              <tbody>
              <tr>
                <td><p>Email:&nbsp;</p></td>
                <td><p><a href="mailto:enquiries@wineaustralia.com">enquiries@wineaustralia.com</a></p></td>
              </tr>
              <tr>
                <td><p>Postal Address:</p></td>
                <td><p>Wine Australia<br> Attention: Privacy Officer<br> Corner Botanic Road and Hackney Road<br>
                  Adelaide, South Australia 5000<br> Australia</p></td>
              </tr>
              </tbody>
            </table>
            <p>We will do our best to respond to all reasonable requests in a timely manner.</p>
            <p>For information about privacy generally, or if you are unsatisfied with our handling of your request or
              resolving your concerns, you may lodge a complaint with <strong>The Office of the Australian Information
                Commissioner</strong> on 1300 363 992 or via www.oaic.gov.au.</p>
            <p>&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import Kontent from "@/kontent";

export default {
  name: 'Privacy',
  props: {},
  data() {
    return {
      page: null
    }
  },
  mounted() {
    Analytics.trackPage('Privacy')
    Kontent.getItemByType('privacy_page').then((res) => {
      this.page = res.data.items[0]
    })
  }
}
</script>

<style lang="scss">
.terms-content {
  margin-top: 30px;
  margin-bottom: 50px;
  color: #FFF;

  table {
    border: none!important;
    border-top: 1px solid #eceae5!important;
    border-left: 1px solid #eceae5!important;
  }

  table td, table th {
    border: none!important;
    border-bottom: 1px solid #eceae5!important;
    border-right: 1px solid #eceae5!important;
    padding: 0 10px;
  }

  h1, h2, h3, h4, h5 {
    margin-bottom: 20px;
    margin-top: 40px;
    font-weight: bold;
  }

  p {
    margin: 15px 0;
  }

  a {
    text-decoration: underline;
  }

  ul {
    margin: 20px 0;
    padding-left: 25px;

    > li {
      list-style-type: disc;
    }
  }

  ol {
    margin: 20px 0;
    padding-left: 25px;

    > li {
      list-style-type: decimal;
    }
  }
}
</style>
